import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const Paper = makeShortcode("Paper");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ensure-no-alcohol-and-other-drug-use"
    }}>{`Ensure no alcohol and other drug use`}</h1>
    <p>{`It is not uncommon for teenagers to experiment with alcohol or other drugs. However, teenagers who are anxious or depressed are more likely to use alcohol and other drugs. Unfortunately, using drugs or alcohol can make their problems worse. `}<i>{`What can I do?`}</i></p>
    <ul>
      <li parentName="ul">{`Find out more about preventing alcohol misuse in teenagers by reading the `}<i>{`Parenting Guidelines for Adolescent Alcohol Use`}</i>{`. You will find the link in the `}<GoTo to="/appendix" mdxType="GoTo">{`Appendix`}</GoTo>{` page after you complete this module.`}</li>
      <li parentName="ul">{`Have a family rule that drugs and alcohol are off limits for your teenager. Research shows that even a few sips of alcoholic drinks at home can increase a teenager’s risk of later health problems.`}</li>
      <li parentName="ul">{`Think about how your teenager might get access to these substances. Parents can unknowingly provide their teenagers with alcohol and drugs, e.g. beer in the fridge or cigarettes on the kitchen bench.`}</li>
      <li parentName="ul">{`Set an example by using alcohol responsibly and not using drugs.`}</li>
    </ul>
    <Paper mdxType="Paper">
  <p>
    <b>
      Research<sup>8,9,10</sup> shows that:
    </b>
  </p>
  <p>
    Teenagers with symptoms of depression and anxiety are at a greater risk of using alcohol, nicotine or illicit drugs.
  </p>
  <p>Drug and alcohol use is harmful for teenagers' physical health and brain development.</p>
    </Paper>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/36.svg" alt="Drinking and smoking" mdxType="SingleImage" />
    <sup>
  8,9,10. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      